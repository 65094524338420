import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ButtonGroup, FormContainer, FormField, Input, TextArea, Button, StyledForm, Error } from './constant';

const Form = ({ closeModal, onSubmit }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <FormContainer>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormField>
            <label htmlFor="subject">Your Name</label>
            <Input
                id="name"
                {...register('name', { required: 'Name is required' })}
            />
            {errors.phone && <Error>{errors.phone.message}</Error>}
        </FormField>
        <FormField>
            <label htmlFor="subject">Phone</label>
            <Input
                id="phone"
                {...register('phone', { required: 'Phone is required', type: 'tel' })}
            />
            {errors.phone && <Error>{errors.phone.message}</Error>}
        </FormField>
        <FormField>
            <label htmlFor="subject">Subject</label>
            <Input
                id="subject"
                {...register('subject', { required: 'Subject is required' })}
            />
            {errors.subject && <Error>{errors.subject.message}</Error>}
        </FormField>
        <FormField>
            <label htmlFor="body">Body</label>
            <TextArea
                id="body"
                rows="4"
                {...register('body', { required: 'Body is required' })}
            />
            {errors.body && <Error>{errors.body.message}</Error>}
        </FormField>
        <ButtonGroup>
            <Button type="submit" primary>Submit</Button>
            <Button type="button" onClick={closeModal}>Cancel</Button>
        </ButtonGroup>
        </StyledForm>
    </FormContainer>
  );
};

export default Form;

Form.propTypes = {
    closeModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
