export const services = [
    { name: 'Choroegrapher', icon: 'emoji_people'}, 
    { name: 'Electrician', icon: 'electrical_services'},
    { name: 'Plumber', icon: 'plumbing'},
    { name: 'Carpenter', icon: 'carpenter'},
    { name: 'Painter', icon: 'brush'}, 
    { name: 'Pest Control', icon: 'pest_control'}, 
    { name: 'Salon', icon: 'sentiment_very_satisfied'}, 
    { name: 'Appliance Repair', icon: 'ac_unit'}, 
    { name: 'General help', icon: 'person_raised_hand'}, 
];
