import React, { useState } from 'react';
import contactImage from '../../icons/contact.jpg'
import { BackgroundImage, CallButton, CanHelp, ContactContainer, ContactInfo, ContentSection, EMAIL, EmailLink, Heading, PHONE_NUMBER, Para } from './constant';
import Form from './Form';
import Modal from 'react-modal';

const Contact = () => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const onPhoneClick = () => {
    const phoneRedirect = `tel:${PHONE_NUMBER}`;
    window.open(phoneRedirect, '_blank'); 
  };

  const onMailSubmit = data => {
    const completeBody = `Hey, My name is ${data.name}. ${data.body} and My phone number is ${data.phone}.`
    const mailRedirect = `mailto:${EMAIL}?subject=${encodeURIComponent(data.subject)}&body=${encodeURIComponent(completeBody)}`;
    closeModal();
    window.open(mailRedirect, '_blank'); 
  };

  const customStyles = {
    content: {
      width: '500px',
      height: '500px',
      padding: '32px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <ContactContainer>
      <ContentSection>
        <CanHelp>How can we help you ?</CanHelp>
        <Heading>Contact us</Heading>
        <Para>
          We&apos;re here to help and answer any questions you might have.
          We look forward to hearing from you!
        </Para>
        <ContactInfo>
          <span className="material-icons">call</span>
          <CallButton onClick={onPhoneClick}>{PHONE_NUMBER}</CallButton>
        </ContactInfo>
        <ContactInfo>
          <span className="material-icons">mail</span>
          <EmailLink onClick={() => setShowModal(true)}>{EMAIL}</EmailLink>
        </ContactInfo>
      </ContentSection>
      <BackgroundImage src={contactImage} alt='team' />
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Form closeModal={closeModal} onSubmit={onMailSubmit} />
      </Modal>
    </ContactContainer>
  );
};

export default Contact;
