import styled from "styled-components";

export const PHONE_NUMBER = '+917703887065';
export const EMAIL = 'Crownpiece0003@gmail.com';

export const ContactContainer = styled.div`
  display: flex;
  padding: 32px;
  justify-content: space-between;
`

export const CanHelp = styled.div`
  color: #178a7c;
  font-size: 16px;
  font-weight: 600;
`

export const ContactInfo = styled.div`
  display: flex;
  gap: 20px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;

  .material-icons {
    color: #178a7c;
  }
`

export const CallButton = styled.button`
  color: #006efa;
  font-size: 16px;
  font-weight: 500;
  background: none;
  border: none;
`

export const EmailLink = styled.button`
  color: #006efa;
  font-size: 16px;
  font-weight: 500;
  background: none;
  border: none;
`

export const Heading = styled.div`
  font-size: 40px;
  font-weight: 700;
  margin-top: 10px;
  padding: 0;
  color: #178a7c;
`

export const BackgroundImage = styled.img`
  width: 50%;
`

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Para = styled.p`
  gap: 32px;
  margin-top: 20px;
  color: #178a7c;
  max-width: 400px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  height: 100%;
`;

export const StyledForm = styled.form`
  height: 100%;
  width: 100%;
`;

export const FormField = styled.div`
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Error = styled.span`
    color: #ff4a4a;  
`;

export const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.primary ? '#007bff' : '#6c757d'};
  color: white;
  &:hover {
    background-color: ${props => props.primary ? '#0056b3' : '#5a6268'};
  }
`;