import React from 'react';
import styled from 'styled-components';
import teamImage from '../../icons/team.jpeg';

const About = () => {
  return (
    <AboutContainer>
      <BackgroundImage src={teamImage} alt='team' />
      <ContentSection>
        <Para>
          <SubTopic>Welcome to Crownpiece</SubTopic>
          At Crownpiece, we are dedicated to connecting you with the best local service providers in your area. 
          Our mission is to make it easy and convenient for you to find and book trusted professionals for a wide range of services, 
          from electricians and plumbers to dance choreographers and tutors.
        </Para>
        <Para>
          <SubTopic>Our Story</SubTopic>
          Founded in 2022, 
          Crownpiece was created to address the challenges people face when looking for reliable service providers. 
          We noticed that finding a trusted professional often involves a lot of time, research, and uncertainty.
          Our goal is to simplify this process by offering a platform where users can quickly find verified service providers 
          based on their location and specific needs.
        </Para>
        <Para>
          <SubTopic>Our Vision</SubTopic>
          We envision a world where finding and booking trusted service providers is effortless and stress-free. 
          Our platform is designed to bring convenience, reliability, and transparency to the service booking process.
        </Para>
        <Para>
          <SubTopic>Meet Our Team</SubTopic>
          Our dedicated team is passionate about delivering the best experience to our users.
          We work tirelessly to ensure that Crownpiece remains a trusted and valuable resource for both service providers and customers.
        </Para>
      </ContentSection>
    </AboutContainer>
  );
};

export default About;

const AboutContainer = styled.div`
  display: flex;
  padding: 50px;
  justify-content: space-between;
`

const BackgroundImage = styled.img`
  width: 50%;
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`

const Para = styled.p`
  gap: 32px;
`

const SubTopic = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
`