import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './pages/Navbar';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import styled from 'styled-components';

function App() {
  return (
    <AppWrapper>
      <Router>
          <Navbar />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/home" exact element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} /> 
          </Routes>
      </Router>
    </AppWrapper>
  );
}

export default App;

const AppWrapper = styled.div`
  background: white;
  padding: 2rem;
  height: 100vh;
`;