import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../icons/logo.png';

const Navbar = () => {
  return (
    <Nav>
      <Image src={Logo} alt="" />
      <Ul>
        <Li>
          <StyledLink to="/home">Home</StyledLink>
        </Li>
        <Li>
          <StyledLink to="/about">About</StyledLink>
        </Li>
        <Li>
          <StyledLink to="/contact">Contact</StyledLink>
        </Li>
      </Ul>
    </Nav>
  );
};

export default Navbar;

const Nav = styled.nav`
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid #178a7c;
  border-radius: 48px;
`;

const Ul = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  margin: 0 1rem;
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    color: #006efa;
  }
`;

const Image = styled.img`
  max-width: 100px;
  border-radius: 48px;
`;
