import React, { useState } from 'react';
import styled from 'styled-components';
import { services } from './constant';
import { WHATSAPP_GROUP_LINK } from '../../Constants';

const Home = () => {
  const [phoneNumber] = useState(+917703887065);
  
  const onServiceClick = () => {
    window.open(WHATSAPP_GROUP_LINK, '_blank'); 
  };

  const renderServiceCard = service => (
    <ServiceCard onClick={() => onServiceClick(service.name)}>
      <span className="material-icons">{service.icon}</span>
      {service.name}
    </ServiceCard>
  )

  return (
    <HomeWrapper>
      <LeftSection>
        <OneLiner>Find Trusted Crownpiece Professionals Near You and book for seamless services</OneLiner>
        <Green>Easily and Quickly!!</Green>
        <Para>
          Use our intuitive search feature to find service providers based on your location and service needs. 
          Get our top-rated professional services at your doorstep.
        </Para>
        <ContactButton onClick={onServiceClick}> 
          Schedule Now
        </ContactButton>
      </LeftSection>
      <ServiceContainer>
        <ServicesHeading>
          Services at your doorstep...
          <ServiceGIF src="https://media.tenor.com/hQSLV5xmXeAAAAAi/mechanic-screw.gif" alt="Services" />
        </ServicesHeading>
        <Services>
          {services.map(service => (
            renderServiceCard(service)
          ))}
        </Services>
      </ServiceContainer>
    </HomeWrapper>
  );
};

export default Home;


const HomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 48px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;

const Services = styled.div`
  display: grid;
  padding: 32px;
  gap: 50px;
  margin-right: 80px;
  grid-template-columns: repeat(3, 1fr); 
  grid-template-rows: repeat(2, 1fr);
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  :hover {
    transform: scale(1.1);
  }
`;
  
const ServiceCard = styled.button`
  display: flex;
  width: 100%;
  background: #f5f5f5;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease; 
  cursor: pointer;
  overflow: hidden;
  border: none;

  .material-icons {
    width: 24px;
    font-size: 30px;
    color: #178a7c;
    transition: transform 0.3s ease; 
  }

  :hover {
    transform: scale(1.3);
  }
`;

const LeftSection = styled.div`
  color: black;
  height: 500px;
  align-content: center;
  max-width: 430px;
`;

const OneLiner = styled.div`
  font-size: 36px;
  font-weight: 700;
`;

const Para = styled.p`
  font-size: 16px;
`;

const Green = styled.div`
  font-size: 36px;
  font-weight: 700;
  color: #178a7c;
`;

const ContactButton = styled.button`
  background: #178a7c;
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  margin-top: 4px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px #178a7c;
  }
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

const ServicesHeading = styled.div`
  font-size: 36px;
  margin-bottom: 36px;
`;

const ServiceGIF = styled.img`
  width: 120px;
  margin-left: 20px;
`
